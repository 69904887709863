<template>
  <svg
    width="17"
    height="20"
    viewBox="0 0 17 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.197 5.464C6.82 5.167 7.634 5 8.5 5C9.366 5 10.181 5.167 10.803 5.464C11.378 5.739 12 6.24 12 7C12 7.76 11.379 8.26 10.803 8.536C10.181 8.833 9.366 9 8.5 9C7.634 9 6.819 8.833 6.197 8.536C5.622 8.261 5 7.76 5 7C5 6.24 5.622 5.74 6.197 5.464ZM6.737 6.592C6.272 6.814 6.25 7 6.25 7C6.25 7 6.272 7.186 6.737 7.408C7.156 7.608 7.778 7.75 8.5 7.75C9.222 7.75 9.844 7.609 10.263 7.408C10.728 7.186 10.75 7 10.75 7C10.75 7 10.728 6.814 10.263 6.592C9.844 6.392 9.222 6.25 8.5 6.25C7.778 6.25 7.156 6.391 6.737 6.592ZM8.5 10C9.91 10 11.17 9.567 11.995 8.89C11.998 8.927 12 8.963 12 9C12 10.105 10.433 11 8.5 11C6.567 11 5 10.105 5 9C5 8.963 5.002 8.927 5.005 8.89C5.83 9.567 7.09 10 8.5 10ZM8.5 12C9.91 12 11.17 11.567 11.995 10.89C11.998 10.927 12 10.963 12 11C12 12.105 10.433 13 8.5 13C6.567 13 5 12.105 5 11C5 10.963 5.002 10.927 5.005 10.89C5.83 11.567 7.09 12 8.5 12ZM2.5 0C1.83696 0 1.20107 0.263392 0.732233 0.732233C0.263392 1.20107 0 1.83696 0 2.5V17.5C0 18.163 0.263392 18.7989 0.732233 19.2678C1.20107 19.7366 1.83696 20 2.5 20H15.75C15.9489 20 16.1397 19.921 16.2803 19.7803C16.421 19.6397 16.5 19.4489 16.5 19.25C16.5 19.0511 16.421 18.8603 16.2803 18.7197C16.1397 18.579 15.9489 18.5 15.75 18.5H2.5C2.23478 18.5 1.98043 18.3946 1.79289 18.2071C1.60536 18.0196 1.5 17.7652 1.5 17.5H15.75C15.9489 17.5 16.1397 17.421 16.2803 17.2803C16.421 17.1397 16.5 16.9489 16.5 16.75V2.5C16.5 2.1717 16.4353 1.84661 16.3097 1.54329C16.1841 1.23998 15.9999 0.964379 15.7678 0.732233C15.5356 0.500087 15.26 0.315938 14.9567 0.190301C14.6534 0.0646644 14.3283 0 14 0H2.5ZM15 16H1.5V2.5C1.5 2.23478 1.60536 1.98043 1.79289 1.79289C1.98043 1.60536 2.23478 1.5 2.5 1.5H14C14.2652 1.5 14.5196 1.60536 14.7071 1.79289C14.8946 1.98043 15 2.23478 15 2.5V16Z"
      fill="#FAE9EC"
    />
  </svg>
</template>

<script>
export default {}
</script>

<style></style>
